import React from "react";
import smile from "../emoji1.jpg";
import littlesmile from "../emoji3.jpg";
import bigsmile from "../emoji2.jpg";
function HeroInfo() {
  return (
    <div className="bg-custom-radial relative ">
      {/* <div class="custom-shape-divider-top">
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1200 120"
    preserveAspectRatio="none"
  >
    <defs>
      <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor="black" />
        <stop offset="100%" stopColor="#05010a" />
      </linearGradient>
      <filter id="white-shadow" x="-50%" y="-50%" width="200%" height="200%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="6" />
        <feOffset dx="4" dy="4" result="offsetblur" />
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.7" />
        </feComponentTransfer>
        <feFlood flood-color="black" result="white-flood" />
        <feComposite in="white-flood" in2="offsetblur" operator="in" result="white-shadow" />
        <feMerge>
          <feMergeNode in="white-shadow" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>

    <path
      fill="url(#gradient1)"
      filter="url(#white-shadow)"
      className="SILshape-fill"
      d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
    ></path>
  </svg>
</div> */}
      <section id="infosection">
        <div className="pt-20 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 py-24 mx-auto w-[90%] sm:w-[75%] text-white">
          <div className="info-box text-white text-center bg-white bg-opacity-5 backdrop-blur-lg   p-4 rounded-xl">
            <div className="info-box-head flex flex-col items-center">
              <div className="info-box-img  py-2 ">
                <img
                  className="h-[6rem] rounded-full"
                  loading="lazy"
                  src={smile}
                  alt=""
                />
              </div>
              <div className="info-box-title text-2xl ">
                {" "}
                Lorem ipsum dolor sit amet
              </div>
            </div>
            <div className="info-box-desc">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos
              iusto dolores temporibus velit ducimus qui alias quis quibusdam
              eius nam. Libero reprehenderit earum at officia. Deleniti animi
              similique accusantium praesentium?
            </div>
          </div>
          <div className="info-box text-white text-center bg-white bg-opacity-5 backdrop-blur-lg  p-4 rounded-lg">
            <div className="info-box-head flex flex-col items-center">
              <div className="info-box-img px-2 py-2 ">
                <img
                  className="h-[6rem] rounded-full"
                  src={littlesmile}
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="info-box-title text-2xl ">
                {" "}
                Lorem ipsum dolor sit amet
              </div>
            </div>
            <div className="info-box-desc">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos
              iusto dolores temporibus velit ducimus qui alias quis quibusdam
              eius nam. Libero reprehenderit earum at officia. Deleniti animi
              similique accusantium praesentium?
            </div>
          </div>{" "}
          <div className="info-box text-white text-center bg-white bg-opacity-5 backdrop-blur-lg  p-4 rounded-lg">
            <div className="info-box-head flex flex-col items-center">
              <div className="info-box-img px-2 py-2 ">
                <img
                  className="h-[6rem] rounded-full"
                  src={bigsmile}
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="info-box-title text-2xl ">
                {" "}
                Lorem ipsum dolor sit amet
              </div>
            </div>
            <div className="info-box-desc">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos
              iusto dolores temporibus velit ducimus qui alias quis quibusdam
              eius nam. Libero reprehenderit earum at officia. Deleniti animi
              similique accusantium praesentium?
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HeroInfo;
