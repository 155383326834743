import { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Star from "./StarIcon";
const testimonials = [
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum officia unde laboriosam dolores iure earum iste itaque, ea repellendus consequatur, cupiditate eveniet repudiandae. Nihil eveniet voluptatum maiores provident non. Modi.",

    author: "A",
  },
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum officia unde laboriosam dolores iure earum iste itaque, ea repellendus consequatur, cupiditate eveniet repudiandae. Nihil eveniet voluptatum maiores provident non. Modi.",

    author: "B",
  },
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum officia unde laboriosam dolores iure earum iste itaque, ea repellendus consequatur, cupiditate eveniet repudiandae. Nihil eveniet voluptatum maiores provident non. Modi.",

    author: "C",
  },
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum officia unde laboriosam dolores iure earum iste itaque, ea repellendus consequatur, cupiditate eveniet repudiandae. Nihil eveniet voluptatum maiores provident non. Modi.",

    author: "D",
  },
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum officia unde laboriosam dolores iure earum iste itaque, ea repellendus consequatur, cupiditate eveniet repudiandae. Nihil eveniet voluptatum maiores provident non. Modi.",

    author: "E",
  },

  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum officia unde laboriosam dolores iure earum iste itaque, ea repellendus consequatur, cupiditate eveniet repudiandae. Nihil eveniet voluptatum maiores provident non. Modi.",

    author: "F",
  },

  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum officia unde laboriosam dolores iure earum iste itaque, ea repellendus consequatur, cupiditate eveniet repudiandae. Nihil eveniet voluptatum maiores provident non. Modi.",

    author: "G",
  },
];
const infiniteTestimonials = testimonials.concat(testimonials).concat(testimonials).concat(testimonials);

export default function Reviews() {
  const [position, setPosition] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const visibleItems = 3;
  const itemCount = infiniteTestimonials.length;

  const next = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setPosition((prev) => (prev + 1) % (itemCount - visibleItems + 1));
    setTimeout(() => setIsAnimating(false), 500);
  };

  const prev = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setPosition(
      (prev) =>
        (prev - 1 + (itemCount - visibleItems + 1)) %
        (itemCount - visibleItems + 1)
    );
    setTimeout(() => setIsAnimating(false), 500);
  };

  return (
    <div className="w-[90%] mx-auto p-4 mt-28 sm:mt-52 sm:mb-72">
      <div className="review-head text-white text-5xl mb-4 sm:mb-8">
        Kullanıcı Yorumları
      </div>

      <div className="w-full gap-3 mx-auto">
        <div className="flex-1 overflow-hidden">
          <div
            className="slider-container flex gap-4 transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(${
                window.innerWidth < 768
                  ? -position * 100
                  : -position * (100 / visibleItems)
              }%)`,
              width: "100%", // Ensure that the width of the entire container is full-screen width
            }}
          >
            {infiniteTestimonials.map((item, index) => (
              <div
                key={index}
                className="w-[95%] sm:w-[calc(100%/3.11)] flex-none box-border" // Flex-none prevents box stretching, w-full ensures it fits on mobile
              >
                <div className="review-box rounded-2xl grid grid-cols-1 gap-4 p-6 sm:p-12 bg-customPurple-light border border-customPurple-light h-full">
                  <div className="review-star">
                    <Star />
                  </div>
                  <p className="text-xl text-white mb-4">{item.quote}</p>
                  <div className="mt-auto">
                    <p className="font-semibold text-white">{item.author}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="icons flex gap-4 float-right m-4">
          <button
            onClick={prev}
            disabled={isAnimating}
            className="flex-none w-12 h-12 flex items-center justify-center rounded-full bg-white shadow-lg transition-all duration-300 hover:bg-gray-50 hover:scale-110 border border-gray-200"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>
          <button
            onClick={next}
            disabled={isAnimating}
            className="flex-none w-12 h-12 flex items-center justify-center rounded-full bg-white shadow-lg transition-all duration-300 hover:bg-gray-50 hover:scale-110 border border-gray-200"
          >
            <ChevronRight className="w-6 h-6 text-gray-600" />
          </button>
        </div>
      </div>
    </div>
  );
}
