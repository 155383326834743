import { useState, useEffect, useMemo } from "react";
import firstImg from "../1.png";
import secondImg from "../2.png";
import thirdImg from "../3.png";

function HoverInfoSection() {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [imageSrc, setImageSrc] = useState(firstImg);
  const [opacity, setOpacity] = useState(1);

  // Memoize the images array so it doesn't get recreated on every render
  const images = useMemo(() => [firstImg, secondImg, thirdImg], []);

  const [activeDiv, setActiveDiv] = useState("0");

  // Görsel geçişi için useEffect kullanımı
  useEffect(() => {
    if (hoveredCard !== null) {
      // Görsel kaybolsun
      setOpacity(0);
      // Görsel kaybolduktan sonra kaynak değiştirilsin ve opaklık geri gelsin
      const timeoutId = setTimeout(() => {
        setImageSrc(images[hoveredCard]); // İlk resim yerine seçili resmi güncelliyoruz
        setOpacity(1); // Görsel tekrar opak hale gelsin
      }, 350); // 350ms geçiş süresi

      return () => clearTimeout(timeoutId);
    }
  }, [hoveredCard, images]);

  // Otomatik geçiş için useEffect
  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveDiv((prevDiv) => {
        const nextDiv = (parseInt(prevDiv) + 1) % images.length; // Sıradaki kutuya geç
        setHoveredCard(nextDiv); 
        return nextDiv.toString();
      });
    }, 4000); // Her 4 saniyede bir geçiş

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <div className="bg-black relative">
      <section id="hoverinfo">
        <div className="hover-info grid pt-32 mx-auto grid-cols-1 md:grid-cols-2 w-[90%] md:w-[75%] text-white">
          <div className="hover-info-text">
            <div className="hover-info-head">
              <h1 className="text-4xl font-euclid text-customGray">
                Lorem ipsum dolor sit amet
              </h1>
              <p>
                Lorem ipsum dolor sit amet Lorem ipsum dolor amet Lorem ipsum
                dolor amet Lorem ipsum dolor amet Lorem ipsum dolor sit amet
              </p>
            </div>
            <div className="hover-info-box-container">
              <div
                onClick={() => setActiveDiv("0")}
                tabIndex={0}
                className={`hover-info-box w-[100%] shadow-custom-4-sided rounded-lg transition-all delay-600 shadow-customPurple/70 my-6 p-4 cursor-pointer ${
                  activeDiv === "0" ? "bg-customPurple-light/80" : "bg-none"
                }`}
                onFocus={() => setHoveredCard(0)}
                onBlur={() => setHoveredCard(null)}
              >
                <div className="hover-info-box-title text-customGray text-xl">
                  Lorem imsum dolor sit amet
                </div>{" "}
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                ratione ea vero neque. A itaque voluptate ipsam dolor
                consectetur, vel vero quam omnis, non accusantium deleniti
                laborum, nisi aliquam commodi.
              </div>
              <div
                onClick={() => setActiveDiv("1")}
                tabIndex={0}
                className={`hover-info-box w-[100%] shadow-custom-4-sided rounded-lg transition-all delay-600 shadow-customPurple/70 my-6 p-4 cursor-pointer ${
                  activeDiv === "1" ? "bg-customPurple-light/80" : "bg-none"
                }`}
                onFocus={() => setHoveredCard(1)}
                onBlur={() => setHoveredCard(null)}
              >
                <div className="hover-info-box-title text-customGray text-xl">
                  Lorem imsum dolor sit amet
                </div>{" "}
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                ratione ea vero neque. A itaque voluptate ipsam dolor
                consectetur, vel vero quam omnis, non accusantium deleniti
                laborum, nisi aliquam commodi.
              </div>
              <div
                onClick={() => setActiveDiv("2")}
                tabIndex={0}
                className={`hover-info-box shadow-custom-4-sided rounded-lg transition-all delay-600 shadow-customPurple/70 my-6 p-4 cursor-pointer ${
                  activeDiv === "2" ? "bg-customPurple-light/80" : "bg-none"
                }`}
                onFocus={() => setHoveredCard(2)}
                onBlur={() => setHoveredCard(null)}
              >
                <div className="hover-info-box-title text-xl">
                  Lorem imsum dolor sit amet
                </div>{" "}
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                ratione ea vero neque. A itaque voluptate ipsam dolor
                consectetur, vel vero quam omnis, non accusantium deleniti
                laborum, nisi aliquam commodi.
              </div>
            </div>
          </div>

          <div className="hover-info-image-container">
            <div className="hover-info-image">
              <img
                src={imageSrc}
                alt="Hover effect"
                className="mx-auto hidden sm:block md:h-[80vh] transition-opacity duration-500"
                style={{ opacity: opacity }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HoverInfoSection;
